import styles from './Work.module.css'
import { CiLink } from "react-icons/ci";
import { useTranslation } from 'react-i18next';
const Work = ({ title, authors, year, link, place, keys, country }) => {

    const { t } = useTranslation();

    return (
        <div className={styles.work} key={keys}>

            <h3>{title}</h3>

            {authors && <div className={styles.authors}>
                <p id={styles.author}> {t('Outcomes.author', { ns: 'Results' })}:</p>
                <p > {authors}</p>
            </div>}

            <h3 id={styles.subtitle}>{place}</h3>

            {country && <div className={styles.data}>
                <p id={styles.data}> {t('Outcomes.country', { ns: 'Results' })}:</p>
                <p > {country}</p>
            </div>}
            <div className={styles.data}>
                <p id={styles.data}> {t('Outcomes.year', { ns: 'Results' })}:</p>
                <p > {year}</p>
            </div>
            {link && <a href={link}>
                <div className={styles.link}>
                    <CiLink id={styles.linkimg} />
                    <div id={styles.msg}><p> Link </p></div>
                </div>
            </a>}
        </div>
    )
}

export default Work;
