import React, { useState } from 'react';
import { FcOk } from "react-icons/fc";
import ModalResults from '../../components/ModalResults';

import styles from './Outcome.module.css'

function Outcome({ num, title, works = [] }) {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const showLoading = () => {
        setOpen(true);
        setLoading(true);

        // Simple loading mock. You should add cleanup logic in real world.
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const set = () => {
        setOpen(false);
    }

    return (
        <div className={styles.container}>
            <div>
                <button onClick={showLoading} className={styles.circle}>
                    <h2>{num}</h2>
                </button>
                <ModalResults 
                title = {title}
                loading ={loading} 
                open={open} status={set} 
                works={works}/>
            </div>
            <div className={styles.text}>
                <div>
                    <h3><FcOk />  {title}</h3>
                </div>
            </div>


        </div>
    )
}

export default Outcome;