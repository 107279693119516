import styles from './Team.module.css'


function Team({ name, desc, photo, link }) {
    return (
        <div className={styles.container}>
            <div>
                <div className={styles.photo}>
                    <a href={link} target="_blank" rel="noreferrer"><img src={photo} alt={name}/></a>
                </div >
            </div>
            <div className={styles.text}>
                <div>
                    <h3>{name}</h3>
                </div>
                <div>
                    <p>{desc}</p>
                </div>
            </div>
        </div>
    )
}

export default Team;