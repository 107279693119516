
import flockVideo from '../../img/Canva/First_partopraxis.webm'
import styles from './Sub1HomePage.module.css'
import { useTranslation } from 'react-i18next';

import flockPhoto from '../../img/Canva/Adriano - 1 Percurso 20.jpg'
const Sub1HomePage = () => {
    const { t } = useTranslation();
    return (
        <section className={styles.hero}>
            {/* Vídeo de fundo */}
            <div className={styles.flock}>
                <video
                    src={flockVideo}
                    autoPlay
                    loop
                    muted
                    id={styles.flockin}
                    aria-label="Flock video with Matosinhos"
                    style={{ pointerEvents: 'none' }}
                />
                <img src={flockPhoto} alt='Flock' />
            </div>
            {/* Conteúdo sobreposto ao vídeo */}
            <div className={styles.contentOne}>
                <div className={styles.texts}>
                    <h1>
                        {t('Sub1HomePage.title1', { ns: 'Home' })}
                    </h1>
                    <p className={styles.typewriter}>{t('Sub1HomePage.discripton', { ns: 'Home' })}</p>
                </div>
            </div>
        </section>
    );
};

export default Sub1HomePage;