import styles from './Square.module.css'

function Square({link, img,alt }){
    return (
        <div className={styles.sizeimg}>
            <a href={link} target='_blank' rel="noreferrer"><img src={img} alt={alt} /></a>
        </div>
    )
} 

export default Square