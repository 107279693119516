import styles from './Box_Text.module.css'

import ok from '../img/Canva/ok.png'

function BoxText({text}){

    return (
        <div className={styles.container}>
            <p>{text}</p>
            <img src={ok} alt="ok" />
        </div>
    )

}

export default BoxText;

