import styles from './ItensFooter.module.css'

function ItensFooter({ text, logo, link, alt, width }) {


    return (
        <div className={styles.itens}>
            {/* Sempre renderiza a div, mas aplica um estilo condicional para texto vazio */}
            <div className={text ? styles.text : styles.emptyText}>
                {text}
            </div>
            <div>
                <a href={link} target="_blank" rel="noreferrer">
                    <img src={logo} width={width} alt={alt} />
                </a>
            </div>

        </div>
    )

}

export default ItensFooter;