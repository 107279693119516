import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Rola para o topo sempre que o pathname mudar
  }, [pathname]);

  return null; // Não renderiza nada visualmente
}

export default ScrollToTop;
