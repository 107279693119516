import React, { useRef } from 'react';
import { Form, Input, Button, message } from 'antd';
import emailjs from '@emailjs/browser';
import flock from '../img/Canva/flock.png'
import cloud from '../img/Canva/cloud.svg'

import { useTranslation } from 'react-i18next';

import styles from './ContactsPage.module.css';

const ContactForm = () => {
  const { t } = useTranslation();
  const form = useRef();

  const sendEmail = (values) => {
    // Substitua pelos IDs do seu serviço, template e usuário
    const SERVICE_ID = 'service_x6j2hk6';
    const TEMPLATE_ID = 'template_jadffr7';
    const PUBLIC_KEY = 'QZcu0gNzqlvDB5SoS';

    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, values, PUBLIC_KEY)
      .then(
        (result) => {
          message.success('Mensagem enviada com sucesso!');
        },
        (error) => {
          message.error('Erro ao enviar a mensagem. Tente novamente.');
        }
      );
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2> {t('title', { ns: 'Contacts' })}</h2>
        <p>
        {t('subtitle', { ns: 'Contacts' })}
        </p>
      </div>
      <Form
        layout="vertical"
        onFinish={sendEmail}
        ref={form}
        className={styles.contact_form}
      >
        <Form.Item
          label={t('name', { ns: 'Contacts' })}
          name="nome"
          rules={[{ required: true, message: 'Por favor, insira seu nome!' }]}
        >
          <Input name="nome" />
        </Form.Item>
        <Form.Item
          label={t('email', { ns: 'Contacts' })}
          name="email"
          rules={[{ required: true, message: 'Por favor, insira seu email!' }]}
        >
          <Input type="email" name="email" />
        </Form.Item>
        <Form.Item
          label={t('subject', { ns: 'Contacts' })}
          name="assunto"
          rules={[{ required: true, message: 'Por favor, insira um assunto!' }]}
        >
          <Input type = "subject" name="subject" />
        </Form.Item>
        <Form.Item
          label={t('message', { ns: 'Contacts' })}
          name="subject"
          rules={[{ required: true, message: 'Por favor, insira uma mensagem!' }]}
        >
          <Input.TextArea rows={4} name="mensagem" />
        </Form.Item>
        <Form.Item>
          <Button id={styles.form_button} type="primary" htmlType="submit">
          {t('Send', { ns: 'Contacts' })}
          </Button>
        </Form.Item>
      </Form>
      <div className={styles.cloud}>
                        <img src={cloud} alt="Cloud with Matosinhos" id={styles.cloud1} />
                        <img src={cloud} alt="Cloud with Matosinhos" id={styles.cloud2} />
                    </div>
      <div className={styles.flock}>
        <img src={flock} alt="Flock of Matosinhos" id={styles.flockin} />
      </div>
    </div>
  );
};

export default ContactForm;
