import { Modal } from "antd";

import Work from "./Work";


function ModalResults({ title, loading, open, status, works }) {

    const SendClose = () => {
        status();
    }
    return (
        <Modal
            width={900}
            title={<p>{title}</p>}
            footer={[]}
            closable= {true}
            loading={loading}
            open={open}
            onCancel={() => SendClose()}
        >
            {works.length > 0 && works.map((works) =>(
                <Work
                key={works.title} 
                title={works.title}
                authors={works.authors}
                year={works.year}
                link={works.link}
                place={works.place}
                country = {works.country}
                >
                </Work>
            ))}
        </Modal>
    )
}


export default ModalResults;
