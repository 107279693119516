import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'; // Importa o Leaflet diretamente
import icon from '../../img/Canva/pointer.png'

// Defina o ícone personalizado
const customIcon = L.icon({
    iconUrl: icon, // Substitua pela URL da imagem do ícone
    iconSize: [40, 60],            // Tamanho do ícone (largura, altura)
    iconAnchor: [20, 55],          // Âncora do ícone: metade da largura, altura total (base)
    popupAnchor: [0, -32]          // Posição do popup em relação ao ícone
});

const Leaflet = ({ flock1, flock2, flock3, stable1, stable2, stable3 }) => {



    //console.log(flock1.map((coord) => coord[0]))
    //console.log(stable1.map((coord) => coord))
    //const Stable1 = [stable1.map((coord) => coord)]
    //console.log(Stable1)
    //const stable = [stable[0], stable[0]] 
    //console.log(stable1[0])

    const position = [41.5, -7.5]; // Coordenadas iniciais (latitude, longitude)


    const limeOptions1 = { color: "#fff" }
    const limeOptions2 = { color: "#f0f" }
    const limeOptions3 = { color: "#0f0" }

    return (
        <div>
            <MapContainer
                center={position}
                zoom={8}
                scrollWheelZoom={true}
                style={{ height: "500px", width: "100%" }} // Definindo altura e largura do mapa
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
                />
                <Polygon
                    pathOptions={limeOptions1}
                    positions={flock1}
                ><Popup>
                        123 km quadrado
                    </Popup>
                </Polygon>
                {stable1 && stable1.length > 0 && (
                    <Marker position={stable1[0]} icon={customIcon}>
                        <Popup>Rebanho 1</Popup>
                    </Marker>
                )}
                {stable2 && stable2.length > 0 && (
                    <Marker position={stable2[0]} icon={customIcon}>
                        <Popup>Rebanho 2</Popup>
                    </Marker>
                )}
                {stable3 && stable3.length > 0 && (
                    <Marker position={stable3[0]} icon={customIcon}>
                        <Popup>Rebanho 3</Popup>
                    </Marker>
                )}
                <Polygon
                    pathOptions={limeOptions2}
                    positions={flock2}
                />
                <Polygon
                    pathOptions={limeOptions3}
                    positions={flock3}
                />

            </MapContainer>
        </div >
    );
};

export default Leaflet;
