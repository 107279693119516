import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend) // Carrega as traduções dinamicamente
  .use(LanguageDetector) // Detecta automaticamente o idioma do navegador
  .use(initReactI18next) // Integração com React
  .init({
    lng: 'pt', // Idioma padrão
    fallbackLng: 'en', // Idioma de fallback caso a tradução não exista
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Caminho para os arquivos JSON
    },
    ns: ['Home','Location','Team', 'Steps','Results','Contacts','Navbar','Footer'], // Declara os namespaces (nomes dos arquivos)
    interpolation: {
      escapeValue: false, // React já faz a proteção contra XSS
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'], // Ordem de detecção
      caches: ['cookie'],
    },
  });

export default i18n;
