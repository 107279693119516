import styles from './Steps_Methods.module.css'
import { Image } from 'antd';

function StepsMethods({ num, title, titlesubs, description, background, photos }) {

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.title} style={{ backgroundColor: background }}>
                    <h1>{num}</h1>
                    <h1>{title}</h1>
                    <p style={[]}>{titlesubs}</p>
                </div>
                <div className={styles.description} style={[]}>
                    {description}
                </div>
                <div className={styles.image}>
                    <Image.PreviewGroup
                        items={photos}
                    >
                        <Image
                            width={300}
                            src={photos[0]}
                        />
                    </Image.PreviewGroup>
                </div>
            </div>
            <div className={styles.arrow}></div>
        </div>
    )
}

export default StepsMethods