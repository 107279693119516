import React from 'react';
import Sub1HomePage from './SubHomes/Sub1HomePage';
import Sub2HomePage from './SubHomes/Sub2HomePage';

import styles from './HomePage.module.css'

const HomePage = () => (
  <div >
    <Sub1HomePage className={styles.pag1}/>
    <Sub2HomePage className={styles.pag1}/>
  </div>
);

export default HomePage;

//
