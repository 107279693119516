
import fct from '../img/logo/fct_logo.png'
import cria from '../img/logo/CRIA logo.png'
import cimo from '../img/logo/cimo_logo.png'
import IPB from '../img/logo/IPB_logo.png'
import ItensFooter from '../components/ItensFooter'
import { FaInstagram } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io";


import styles from './Footer.module.css'
import ItensFooterDuplo from '../components/ItensFooterDuplo'
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  return (
  <footer className={styles.footer}>
    <ul className={styles.social_list}>
      <li id='1'>
        <ItensFooterDuplo
        text= {t('InstitutionsProposing', { ns: 'Footer' })}
        logo1={IPB}
        logo2={cimo}
        alt1="IPB"
        alt2="CIMO"
        link1="https://portal3.ipb.pt/index.php/pt/ipb"
        link2 = "https://cimo.ipb.pt" 
        width1 = "200px"
        width2 = "150px"
        />
        
      </li>
      <li id='2'>
        <ItensFooter
          logo={cria}
          alt="cria"
          text={t('ParticipatingInstitution', { ns: 'Footer' })}
          link="https://www.cria.pt"
          width="180px"
        >
        </ItensFooter>
      
      </li >
      <li id='4'>
        <ItensFooter
          text={t('CoFinancing', { ns: 'Footer' })}
          logo={fct}
          alt="fct"
          width="160px"
          link="https://www.fct.pt">
        </ItensFooter>
      </li>
    </ul>
    <div className={styles.networks}>
    <p>{t('FollowOurSocials', { ns: 'Footer' })}</p>
    <a href='https://www.instagram.com/pastopraxis_/' target='_blank' rel="noreferrer"><FaInstagram id={styles.insta}/></a>
    <a href='https://www.linkedin.com/in/grupo-de-investigação-em-ecologia-do-pastoreio-745b3b313/' target='_blank' rel="noreferrer"><IoLogoLinkedin id={styles.linkedin}/></a>
    </div>
    <p className={styles.copy_right}>
      <span >Pastopraxis</span> &copy; 2024 
    </p>
      
  </footer>
  )
}

export default Footer