import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ResultsPage from './pages/ResultsPage';
import ContactsPage from './pages/ContactsPage';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import Container from './layout/Container';

import './App.css';  // Onde o estilo flexbox está definido
import ScrollToTop from './components/ScrollToTop';
import LocationPage from './pages/LocationPage';
import StepsAndMethods from './pages/Steps_MethodsPage';
import TeamPartner from './pages/Team_PartnersPage';


const App = () => (
  <Router>
    <ScrollToTop /> {/* Rola para o topo sempre que a rota mudar */}
    < Navbar/>

    <Container customClass="min-height">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/location" element={<LocationPage />} />
        <Route path="/steps" element={<StepsAndMethods />} />
        <Route path="/team" element={<TeamPartner />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
      </Routes>
    </Container>

    <Footer/>
    
    </Router>
);

export default App;
