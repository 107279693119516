
import styles from "./Steps_MethodsPage.module.css";
import StepsMethods from "../components/Steps_Methods";

import seta from '../img/Canva/seta.png'



import one1 from '../img/Steps/StepOne/1.jpg'
import one2 from '../img/Steps/StepOne/2.jpg'

import two1 from '../img/Steps/StepTwo/1.jpg'
import two2 from '../img/Steps/StepTwo/2.jpg'

import three1 from '../img/Steps/StepThree/1.png'
import three2 from '../img/Steps/StepThree/2.jpg'

import four1 from '../img/Steps/StepFour/1.png'
import four2 from '../img/Steps/StepFour/2.jpeg'

import five1 from '../img/Steps/StepFive/1.jpeg'
import five2 from '../img/Steps/StepFive/2.png'

import { useTranslation } from 'react-i18next';

// color in : https://ant.design/docs/spec/colors
function StepsAndMethods() {

  const { t } = useTranslation();
 
  const box1 = [one1, one2]
  const box2 = [two1, two2]
  const box3 = [three1, three2]
  const box4 = [four1, four2]
  const box5 = [five1, five2]

  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <h2> {t('Title', { ns: 'Steps' })}</h2>
      </div>
      <StepsMethods
        num="1"
        title={t('step1', { ns: 'Steps' })}
        titlesubs={t('step1.description', { ns: 'Steps' })}
        description={<ul>
          <li>
          {t('step1.content1', { ns: 'Steps' })}
          </li>
        </ul>}
        background="#73d13d"
        photos={box1}
      />
      <div className={styles.down}>
        <img src={seta} alt="seta" />
      </div>
      <StepsMethods
        num="2"
        title={t('step2', { ns: 'Steps' })}
        titlesubs={t('step2.description', { ns: 'Steps' })}
        description={<ul>
          <li>
          {t('step2.content1', { ns: 'Steps' })}
          </li>
        </ul>}
        background="#52c41a"
        photos={box2}
      />
      <div className={styles.down}>
        <img src={seta} alt="seta" />
      </div>
      <StepsMethods
        num="3"
        title={t('step3', { ns: 'Steps' })}
        titlesubs={t('step3.description', { ns: 'Steps' })}
        description={
          <ul>
            <li>{t('step3.content1', { ns: 'Steps' })}</li>
            <br/>
            <li>{t('step3.content2', { ns: 'Steps' })}</li>
          </ul>
        }
        background="#389e0d"
        photos={box3}
      />
      <div className={styles.down}>
        <img src={seta} alt="seta" />
      </div>
      <StepsMethods
        num="4"
        title={t('step4', { ns: 'Steps' })}
        titlesubs={t('step4.description', { ns: 'Steps' })}
        description={
          <ul>
            <li>{t('step4.content1', { ns: 'Steps' })}</li>
          </ul>
        }
        background="#237804"
        photos={box4}
      />
      <div className={styles.down}>
        <img src={seta} alt="seta" />
      </div>
      <StepsMethods
        num="5"
        title={t('step5', { ns: 'Steps' })}
        titlesubs={t('step5.description', { ns: 'Steps' })}
        description={
          <ul>
            <li>{t('step5.content1', { ns: 'Steps' })}</li>
          </ul>
        }
        background="#135200"
        photos={box5}
      />
      <br></br>
    </section>
  );
}

export default StepsAndMethods;
