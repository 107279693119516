import styles from './FlockNumbers.module.css'

function FlockNumbers({ num, title }) {
    return (
        <div className={styles.container}>
            <div  >
                <p>
                    {title}
                </p>
            </div>
            <div className={styles.box}>
                <p>
                    {num}
                </p>
            </div>
        </div>
    );
}

export default FlockNumbers