import styles from './ItensFooterDuplo.module.css'

function ItensFooterDuplo({ text, logo1,logo2, link1,link2, alt1,alt2,width1, width2 }) {


    return (
        <div className={styles.itens}>
            {/* Sempre renderiza a div, mas aplica um estilo condicional para texto vazio */}
            <div className={text ? styles.text : styles.emptyText}>
                {text}
            </div>
            <div>
                <a href={link1} target="_blank" rel="noreferrer">
                    <img src={logo1} width={width1} alt={alt1} />
                </a>
                <a href={link2} target="_blank" rel="noreferrer">
                    <img src={logo2} width={width2} alt={alt2} />
                </a>
            </div>

        </div>
    )

}

export default ItensFooterDuplo;