import styles from './Navbar.module.css';
import logo from '../img/logo/pastopraxis_logo.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
//import LanguageSwitcher from '../components/LanguageSwitcher';
import { IoIosMenu } from "react-icons/io";
import { useTranslation } from 'react-i18next';

function Navbar() {

  const { t } = useTranslation();
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);


    return () => {
      window.removeEventListener('scroll', handleScroll);

    };
  }, []);

  // Função para rolar ao topo e navegar
  const handleNavigation = (path) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Adiciona uma rolagem suave
    });
    navigate(path); // Navega para a rota especificada
    setIsMenuOpen(false); // Fechar o menu ao navegar
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header >
     
      <nav className={`${styles.navbar} ${scrolled ? styles.scrolled : ''}`}>
        <button id={styles.logopastopraxi} onClick={() => handleNavigation('/')}>
          <img src={logo} alt="pastopraxi" />
        </button>
        <ul className={`${isMenuOpen ? styles.showMenu : ''}`}>
          <li className={styles.item}>
            <button onClick={() => handleNavigation('/')}>{t('Home', { ns: 'Navbar' })}</button>
          </li>
          <li className={styles.item}>
            <button onClick={() => handleNavigation('/location')}>{t('Location', { ns: 'Navbar' })}</button>
          </li>
          <li className={styles.item}>
            <button onClick={() => handleNavigation('/steps')}>{t('StepsAndMethods', { ns: 'Navbar' })}</button>
          </li>
          <li className={styles.item}>
            <button onClick={() => handleNavigation('/team')}>{t('TeamAndPartners', { ns: 'Navbar' })}</button>
          </li>
          <li className={styles.item}>
            <button onClick={() => handleNavigation('/results')}>{t('Results', { ns: 'Navbar' })}</button>
          </li>
          <li className={styles.item}>
            <button onClick={() => handleNavigation('/contacts')}>{t('Contact', { ns: 'Navbar' })}</button>
          </li>
          {/*<li>
            <LanguageSwitcher /> }
          </li>*
          */}
        </ul>
          <button id={styles.menu_icon} onClick={toggleMenu}>
            <IoIosMenu id={styles.TiThMenu} />
          </button>
      </nav>

    </header>
  );
}

export default Navbar;
