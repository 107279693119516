import styles from './Partners.module.css'



function Partners({ link, name, desc,logo }) {
    return (
        <div className={styles.container}>
            <div className={styles.photo}>
                <a href={link} target="_blank" rel="noreferrer">
                    <img src={logo} alt={name} />
                </a>
            </div>
            <div className={styles.text}>
                <h3>{name}</h3>
                <p>{desc}</p>
            </div>
        </div>
    )
}

export default Partners;