import React from 'react';
import styles from './Sub2HomePage.module.css'


import img1 from '../../img/ONU/01.png';
import img2 from '../../img/ONU/02.jpg';
import img3 from '../../img/ONU/03.jpg';
import img8 from '../../img/ONU/08.png';
import img12 from '../../img/ONU/12.jpg';
import img13 from '../../img/ONU/13.jpg';
import img15 from '../../img/ONU/15.jpg';
import BoxText from '../../components/Box_Text';
import circle from '../../img/Canva/circle.png';

import FCT from '../../img/logo/logo-fct.png'
import Square from '../../components/Square';

import { useTranslation } from 'react-i18next';


const Sub2HomePage = () => {

    const { t } = useTranslation();

    return (
        <section className={styles.hero}>
            <div className={styles.contentTwo}>
            <div><h1> {t('Title1', { ns: 'Home' })}</h1></div>
                <p>
                    {t('Content1', { ns: 'Home' })}
                    <br /><br />
                </p>
                <h3>{t('Subtitle1', { ns: 'Home' })}</h3>
                <p>
                    {t('content2', { ns: 'Home' })}
                    <br /><br />
                    {t('content3', { ns: 'Home' })}

                </p>
                <div className={styles.onugrid}>
                    <Square img={img1} alt="logo" logo="logo" link="https://unric.org/pt/objetivo-1-erradicar-a-pobreza-2/" />
                    <Square img={img2} alt="logo" logo="logo" link="https://unric.org/pt/objetivo-2-erradicar-a-fome/" />
                    <Square img={img3} alt="logo" logo="logo" link="https://unric.org/pt/objetivo-3-saude-de-qualidade-2/" />
                    <Square img={img8} alt="logo" logo="logo" link="https://unric.org/pt/objetivo-8-trabalho-digno-e-crescimento-economico/" />
                    <Square img={img12} alt="logo" logo="logo" link="https://unric.org/pt/objetivo-12-producao-e-consumo-sustentaveis/" />
                    <Square img={img13} alt="logo" logo="logo" link="https://unric.org/pt/objetivo-13-acao-climatica/" />
                    <Square img={img15} alt="logo" logo="logo" link="https://unric.org/pt/objetivo-15-proteger-a-vida-terrestre/" />
                </div>
            </div>
            <div className={styles.contentTwo}>
                <p>
                    {t('content4', { ns: 'Home' })}<br /><br />
                </p>
                <h3>{t('Subtitle2', { ns: 'Home' })}</h3>
                <p>{t('content5', { ns: 'Home' })}</p>
                <div className={styles.contentOBJ}>
                    <BoxText id={styles.objbox1} text={t('obj1', { ns: 'Home' })}>
                    </BoxText>
                    <BoxText id={styles.objbox2} text={t('obj3', { ns: 'Home' })}>
                    </BoxText>
                    <BoxText id={styles.objbox1} text={t('obj2', { ns: 'Home' })}>
                    </BoxText>
                </div>
                <p>{t('content6', { ns: 'Home' })}
                </p>
                <img src={circle} alt='socioecosistema'/>
            </div>
           
                <div className={styles.fct}>
                    <a href='https://www.fct.pt' target='_blank' rel="noreferrer"><img src={FCT} alt="fct"></img></a>
                    <p id={styles.strong}>MTS/CAS/0028/2020</p>
                    <p id={styles.weak}>{t('financing1', { ns: 'Home' })}</p>
                    <p id={styles.strong} >125.008,75 €</p>
                    <p>{t('financing2', { ns: 'Home' })}</p>
                    <p id={styles.strong}>{t('financing3', { ns: 'Home' })}</p>
                    <p>{t('financing4', { ns: 'Home' })}</p>
                    <p id={styles.strong}>{t('financing5', { ns: 'Home' })}</p>
                </div>
         
        </section>
    )

}

export default Sub2HomePage;